import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'jmy-tabset-basic',
  templateUrl: './tabset-basic.component.html',
  styleUrls: ['./tabset-basic.component.less']
})
export class TabsetBasicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
